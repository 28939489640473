var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"1000px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.$emit('update:dialogBaixarContasAddFormaPago', false)}},model:{value:(_vm.dialogBaixarContasAddFormaPago),callback:function ($$v) {_vm.dialogBaixarContasAddFormaPago=$$v},expression:"dialogBaixarContasAddFormaPago"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-1 px-3 py-2 lightgray"},[_vm._v(" "+_vm._s(_vm.$tc("global.add"))+" "+_vm._s(_vm.$tc("global.formapagamento"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('update:dialogBaixarContasAddFormaPago', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"py-7"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{staticClass:"ma-0",attrs:{"disabled":_vm.valorRestante == 0 ? true : false,"height":"40px","items":_vm.formaPagamentos,"item-text":"descricao","item-value":"id","loading":_vm.loadingFormaPagamentos,"label":_vm.$tc('global.formapagamento'),"placeholder":"Selecione uma forma de pagamento","clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formaPagamentoSelected),callback:function ($$v) {_vm.formaPagamentoSelected=$$v},expression:"formaPagamentoSelected"}})],1),(
            _vm.formaPagamentoSelected &&
            (_vm.formaPagamentoSelected.id === 6 ||
              _vm.formaPagamentoSelected.id === 7)
          )?_c('v-col',[_c('v-combobox',{staticClass:"ma-0",attrs:{"height":"40px","items":_vm.bandeiras,"item-text":"bandeira","item-value":"id","loading":_vm.loading,"label":_vm.$tc('global.bandeira'),"clearable":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.selectedBandeira),callback:function ($$v) {_vm.selectedBandeira=$$v},expression:"selectedBandeira"}}),_c('v-text-field',{staticClass:"pt-4",attrs:{"label":_vm.$tc('global.codigoAutorizacao'),"outlined":"","dense":"","required":""},model:{value:(_vm.codigoAutorizacao),callback:function ($$v) {_vm.codigoAutorizacao=$$v},expression:"codigoAutorizacao"}})],1):_vm._e(),(_vm.formaPagamentoSelected && _vm.formaPagamentoSelected.id === 4)?_c('BaixaCheque',{attrs:{"valor_restante":_vm.valorRestante,"moeda_venda":_vm.moeda_conta,"allowCheque":_vm.allowCheque,"cheque":_vm.chequeAdd,"cheques_numeros":_vm.cheques_criados},on:{"update:valor_restante":function($event){_vm.valorRestante=$event},"update:allowCheque":function($event){_vm.allowCheque=$event},"update:allow-cheque":function($event){_vm.allowCheque=$event},"update:cheque":function($event){_vm.chequeAdd=$event}}}):_vm._e(),(_vm.formaPagamentoSelected)?[(
              _vm.formaPagamentoSelected.id === 1 ||
              _vm.formaPagamentoSelected.id === 2 ||
              _vm.formaPagamentoSelected.id === 6 ||
              _vm.formaPagamentoSelected.id === 7 ||
              _vm.formaPagamentoSelected.id === 8
            )?[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.formaPagamentoSelected.id === 2)?_c('v-text-field',{attrs:{"label":_vm.$tc('global.documento'),"dense":"","outlined":""},model:{value:(_vm.formaPagamentoItem.documento),callback:function ($$v) {_vm.$set(_vm.formaPagamentoItem, "documento", $$v)},expression:"formaPagamentoItem.documento"}}):_vm._e()],1),(
                _vm.formaPagamentoSelected.id === 1 ||
                _vm.formaPagamentoSelected.id === 2 ||
                _vm.formaPagamentoSelected.id === 6 ||
                _vm.formaPagamentoSelected.id === 7 ||
                _vm.formaPagamentoSelected.id === 8
              )?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.caixas,"loading":_vm.loadingTipoCaixas,"item-text":"descricao","item-value":"id_tipo_caixa","label":_vm.$tc('global.caixa'),"dense":"","outlined":"","hide-details":"","return-object":""},model:{value:(_vm.caixaSelected),callback:function ($$v) {_vm.caixaSelected=$$v},expression:"caixaSelected"}})],1):_vm._e(),(_vm.formaPagamentoSelected.id !== 8)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2 mt-2",attrs:{"icon":"","small":""},on:{"click":_vm.enableSelectMoeda}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-select',{staticClass:"mr-2",attrs:{"items":_vm.moedas,"item-text":"sigla","item-value":"id","label":_vm.$tc('global.moeda', 2),"dense":"","disabled":_vm.blockSelectedMoeda,"clearable":"","outlined":"","hide-details":"","return-object":""},model:{value:(_vm.selectedMoeda),callback:function ($$v) {_vm.selectedMoeda=$$v},expression:"selectedMoeda"}})],1),_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.vCurrencyOptions),expression:"vCurrencyOptions"}],ref:"formaPagamentoValor",staticClass:"input-right",attrs:{"label":_vm.$tc('global.valor'),"outlined":"","dense":"","disabled":_vm.disableValor},on:{"blur":_vm.validatePagamentoValor},model:{value:(_vm.formaPagamentoValor),callback:function ($$v) {_vm.formaPagamentoValor=$$v},expression:"formaPagamentoValor"}}),_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:(
                  _vm.moeda_conta == 'G$' ? _vm.vCurrencyOptions2 : _vm.vCurrencyOptions
                ),expression:"\n                  moeda_conta == 'G$' ? vCurrencyOptions2 : vCurrencyOptions\n                "}],ref:"formaPagamentoCambio",staticClass:"input-right ml-2",attrs:{"label":_vm.$tc('global.cambio'),"outlined":"","dense":"","disabled":_vm.disableValor},on:{"blur":_vm.atualizarConsolidado},model:{value:(_vm.formaPagamentoCambio),callback:function ($$v) {_vm.formaPagamentoCambio=$$v},expression:"formaPagamentoCambio"}}),_c('div',[_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.vCurrencyOptions2),expression:"vCurrencyOptions2"}],ref:"formaPagamentoConsolidado",staticClass:"input-right ml-2",attrs:{"label":_vm.$tc('global.consolidado'),"outlined":"","dense":"","disabled":_vm.disableValor,"error":_vm.formaPagamentoValorInputError,"error-messages":_vm.formaPagamentoValorInputErrorMessage},on:{"blur":_vm.atualizarCambio},model:{value:(_vm.formaPagamentoConsolidado),callback:function ($$v) {_vm.formaPagamentoConsolidado=$$v},expression:"formaPagamentoConsolidado"}})],1)],1):_vm._e()]:_vm._e()]:_vm._e(),(
            _vm.formaPagamentoSelected &&
            _vm.formaPagamentoSelected.id === 8 &&
            _vm.caixaSelected
          )?_c('BaixaAntecipo',{attrs:{"valor_restante":_vm.valorRestante,"moeda_venda":_vm.moeda_conta,"antecipo":_vm.antecipo,"cliente_id":_vm.cliente_id,"antecipos_adicionados":_vm.antecipos_adicionados},on:{"update:valor_restante":function($event){_vm.valorRestante=$event},"update:antecipo":function($event){_vm.antecipo=$event},"push-antecipo":_vm.pushAntecipo}}):_vm._e()],2),(_vm.selectedMoeda && _vm.formaPagamentoSelected.id !== 4)?_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_vm._v(" "+_vm._s(_vm.$tc("global.restante") + " " + _vm.selectedMoeda.sigla)+" "),(_vm.selectedMoeda.sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(_vm.totalRestanteConvertido))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalRestanteConvertido))+" ")])],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),(_vm.formaPagamentoSelected && _vm.formaPagamentoSelected.id !== 8)?_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","small":"","color":"button_2","disabled":_vm.disableAddPagamento},on:{"click":_vm.pushPagamento}},[_vm._v(" "+_vm._s(_vm.$t("global.add"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }